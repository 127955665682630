<template>
  <div>
    <div
      v-if="!iframe && loggableItemHistoryId"
      id="ss_survey_widget"
    />
    <div
      v-else-if="iframe && loggableItemHistoryId"
      class="iframe-container"
    >
      <iframe
        class="responsive-iframe"
        :src="`${url}?office_id=${officeId}&user_name=${userName}&user_id=${userId}&internal_survey_id=${loggableItemHistoryId}`"
        frameBorder="0"
      />
    </div>
    <div
      v-else
    >
      <b-alert
        class="mt-1"
        variant="danger"
        :show="true"
      >
        <div
          class="alert-body"
        >
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />
          <span>Oops! There was an error loading this page. Please refresh and try again.</span>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BEmbed, BAlert } from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BEmbed,
    BAlert,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    officeId: {
      type: Number,
      required: true,
    },
    loggableItemHistoryId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: false,
      default: 0,
    },
    userName: {
      type: String,
      required: false,
      default: '',
    },
    iframe: {
      type: Boolean,
      required: false,
      default: true,
    },
    embedToken: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      host: 'dolphindevelopmentgroup.surveysparrow.com',
      token: '',
      surveyName: '',
    }
  },
  created() {
    try {
      const surveyURL = new URL(this.url)
      const paths = surveyURL.pathname.split('/')
      // eslint-disable-next-line prefer-destructuring
      this.token = this.embedToken // paths[3]
      // eslint-disable-next-line prefer-destructuring
      this.surveyName = paths[2]
      if (!this.iframe) {
        this.initSparrow()
      }
    } catch (e) {
      console.error(`Error loading widget: ${e.message}`)
    }
  },
  mounted() {
    window.addEventListener('message', event => {
      const { origin, data } = event
      if (origin === 'https://dolphindevelopmentgroup.surveysparrow.com' && data) {
        const { type } = data
        if (type === 'surveyCompleted') {
          this.updateSubmissionStatus(this.loggableItemHistoryId)
        }
      }
    })
  },
  methods: {
    ...mapActions('loggableItems', ['updateLoggableItemHistory']),
    initSparrow() {
      window.SS_WIDGET_TOKEN = this.token
      window.SS_ACCOUNT = this.host
      window.SS_SURVEY_NAME = this.surveyName
      // eslint-disable-next-line prefer-rest-params
      const S = () => S.update(arguments)
      S.args = []
      S.update = e => S.args.push(e)
      window.SparrowLauncher = S
      window.sparrowConfig = {
        triggerOnLoad: false,
      }
      this.$loadScript(`https://${this.host}/widget/${this.token}`)
        .then(() => {
          window.SparrowLauncher('popup', {
            variables: {
              office_id: this.officeId,
              user_id: this.userId,
              user_name: this.userName,
              internal_survey_id: this.loggableItemHistoryId,
            },
          })
          console.debug('sparrow is loaded')
          // Script is loaded, do something
        })
        .catch(e => {
          console.debug(e.message)
          console.debug('error loading sparrow')
          // Failed to fetch script
        })
    },
    async updateSubmissionStatus(id) {
      await this.updateLoggableItemHistory({ id, submitted_at: new Date().toISOString() })
    },
  },
}

</script>

<style lang="scss" scoped>
  .iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 70vh;
  }

  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .ss-modal--wrapper {
    display: none !important;
  }
</style>
